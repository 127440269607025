<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading" ref="qContainer">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="[8, 8]">
				<a-col :span="24">
					<a-row :gutter="8">
						<a-col :span="3">
							<a-select
								show-search
								:allowClear="false"
								option-filter-prop="children"
								style="width: 100%"
								v-model="payConditionsCode"
								@change="payConditionsChange"
							>
								<a-select-option v-for="item in PayConditions" :key="item.itemDetailCode">
									{{ item.itemDetailName }}
								</a-select-option>
							</a-select>
						</a-col>
						<a-col :span="3">
							<a-month-picker
								v-model="attCycle"
								@change="onAttCycleChange"
								:allowClear="false"
								style="width: 100%;"
							/>
						</a-col>
						<a-col :span="4">
							<a-tree-select
								@change="handleChange"
								allowClear
								multiple
								:placeholder="l('Company')"
								style="width: 100%"
								:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
								:tree-data="companyTreeList"
								tree-default-expand-all
								v-model="comPanyId"
							/>
						</a-col>
						<a-col :span="4">
							<a-tree-select
								style="width: 100%"
								multiple
								allowClear
								:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
								:tree-data="DepTreeData"
								:placeholder="l('Department')"
								tree-default-expand-all
								v-model="departmentId"
								@change="search"
							>
							</a-tree-select>
						</a-col>

						<a-col :span="3">
							<a-select
								v-model="salaryDetailState"
								@change="search"
								style="width: 100%"
								placeholder="状态"
								allowClear
							>
								<a-select-option v-for="item in SalaryDetailStates" :key="item.itemDetailCode">
									{{ item.itemDetailName }}
								</a-select-option>
							</a-select>
						</a-col>
						<a-col :span="3">
							<a-select
									v-model="ispay"
									@change="search"
									style="width: 100%"
									placeholder="是否发薪"
									allowClear
							>
								<a-select-option v-for="item in ispayList" :key="item.code">
									{{ item.name }}
								</a-select-option>
							</a-select>
						</a-col>
						<a-col :span="4" style="text-align: right">
							<a-input-search
								style="width: 100%;max-width: 200px"
								name="filterText"
								:placeholder="l('SearchWithThreeDot')"
								@search="search"
								enterButton
								v-model="filterText"
							/>
						</a-col>
					</a-row>
				</a-col>
				<a-col :span="24" v-if="attCycleStartTime && attCycleEndTime"
					>考勤周期: {{ attCycleStartTime }}~{{ attCycleEndTime }}
				</a-col>
				<a-col :span="24">
					<a-button class="btn" :type="'danger'" v-if="isGranted('sl_salary_delete')" @click="deletes">
						<a-icon type="delete" />
						<span>{{ l('Delete') }}</span>
					</a-button>
					<a-button :type="'primary'" @click="createSalary()" v-if="isGranted('sl_salary_add')">
						<a-icon type="plus" />
						<span>{{ l('Create') }}</span>
					</a-button>
					<a-button type="primary" @click="calc" v-if="isGranted('sl_salary_detail_calc')">
						<a-icon type="calculator" />
						<span>计算</span>
					</a-button>
					<a-button type="primary" @click="batchAudit" v-if="isGranted('sl_salary_detail_batchaudit')">
						<a-icon type="file-done" />
						<span>批量审核</span>
					</a-button>
					<a-button type="primary" @click="batchEditState" v-if="isGranted('sl_salary_detail_editstate')">
						<a-icon type="edit" />
						<span>批量修改状态</span>
					</a-button>
					<a-button type="primary" @click="batchEditIsPay" v-if="isGranted('sl_salary_detail_editIspay')">
						<a-icon type="edit" />
						<span>批量修改是否发薪</span>
					</a-button>
					<a-button type="primary" @click="pay" v-if="isGranted('sl_salary_detail_calc_pay')">
						<a-icon type="pay-circle" />
						<span>生成工资表</span>
					</a-button>
				</a-col>
				<a-col :span="24">
					<a-button type="primary" @click="importData" v-if="isGranted('sl_month_clac_item_import')">
						<a-icon type="import" />
						<span>变化项导入</span>
					</a-button>
					<a-button type="primary" @click="importPersonTax" v-if="isGranted('sl_person_tax_import')">
						<a-icon type="import" />
						<span>个税导入</span>
					</a-button>
					<a-button type="primary" @click="importPfWithholding" v-if="isGranted('sl_pfwithholding_import')">
						<a-icon type="import" />
						<span>社保补缴导入</span>
					</a-button>

					<a-dropdown
						v-if="
							isGrantedAny(
								'sl_salary_detail_calc_excel,sl_salary_detail_personTaxAll,sl_salary_detail_personTaxAll_NewEmp,sl_month_clac_item_excel'
							)
						"
					>
						<a-menu slot="overlay">
							<a-menu-item v-if="isGranted('sl_month_clac_item_excel')" @click="itemexporter">
								变化项导出
							</a-menu-item>
							<a-menu-item @click="exporter" v-if="isGranted('sl_salary_detail_calc_excel')">
								薪资明细导出
							</a-menu-item>
							<a-menu-item @click="personTaxAll" v-if="isGranted('sl_salary_detail_personTaxAll')">
								个税薪资导出
							</a-menu-item>
							<a-menu-item
								@click="personTaxByNewEmp"
								v-if="isGranted('sl_salary_detail_personTaxAll_NewEmp')"
							>
								新进人员导出
							</a-menu-item>
						</a-menu>
						<a-button>
							导出
							<a-icon type="down" />
						</a-button>
					</a-dropdown>

					<a-button @click="salarySetting" v-if="isGranted('sl_salary_setting')">
						计算设置
					</a-button>

					<a-checkbox
						v-model="isTwoDepGroupExport"
						style="padding-left: 5px"
						@change="isTwoDepGroupExport ? (isThreeDepGroupExport = false) : ''"
					>
						按照二级部门导出
					</a-checkbox>
					<a-checkbox
						v-model="isThreeDepGroupExport"
						style="padding-left: 5px"
						@change="isThreeDepGroupExport ? (isTwoDepGroupExport = false) : ''"
					>
						按照三级部门导出
					</a-checkbox>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				bordered
				:columns="columns"
				:rowSelection="rowSelection"
				@change="sorterChange"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: 5000, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
			>
				<span slot="jobNumber" slot-scope="text, record">
					<a @click="createOrEidtSalary(record)">{{ text }}</a>
				</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import moment from 'moment';
import {
	CalcOrCheckOutInput,
	CompanyServiceProxy,
	DepartmentServiceProxy,
	EmpSalaryDetailServiceProxy,
	EmpSalaryItemsServiceProxy,
	EnterpriseAttendCycleServiceProxy,
} from '../../../shared/service-proxies';
import Dic from '../../../shared/utils/dic';
import SalaryCalcAndPay from './salary-calc-and-pay/salary-calc-and-pay';
import BatchUpdateState from './batch-update-state/batch-update-state';
import modalHelper from '../../../shared/helpers/modal/modal-helper';
import * as _ from 'lodash';
import { fileDownloadService } from '../../../shared/utils';
import DepTreeUntils from '../../organization/department/dep-tree-untils/dep-tree-untils';
import ImportExcelModel from '../month-calc-item/import-excel-model/import-excel-model';
import ImportPersonTaxMode from '../month-calc-item/import-person-tax-model/import-person-tax-model';
import SalarySetting from '../../salary/salary-setting/salary-setting';
import PushToEmpModel from './push-to-emp-model/push-to-emp-model';
import CreateOrEditSalaryItem from './create-or-edit-salary-item/create-or-edit-salary-item';
import ImportPFWithholdingModel from './import-pfwithholding-model/import-pfwithholding-model';
import BatchUpdateIsPay from './batch-update-ispay/batch-update-ispay';
import CreateSalaryDetailEmpModel from './create-salaty-detail-emp-model/create-salaty-detail-emp-model';

// 工号  员工类型
const columns1 = [
	{
		title: '工号',
		dataIndex: 'jobNumber',
		sorter: true,
		ellipsis: true,
		align: 'center',
		fixed: 'left',
		width: 110,
		scopedSlots: { customRender: 'jobNumber' },
	},
	{
		title: '姓名',
		dataIndex: 'realName',
		sorter: true,
		ellipsis: true,
		align: 'center',
		fixed: 'left',
		width: 100,
	},
	{
		title: '身份证号',
		dataIndex: 'identityCard',
		sorter: false,
		align: 'center',
		width: 160,
	},
	{
		title: '入职日期',
		dataIndex: 'hireDate',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 90,
	},
	{
		title: '离职日期',
		ellipsis: true,
		sorter: true,
		dataIndex: 'termDate',
		align: 'center',
		width: 90,
	},
	{
		title: '公司',
		dataIndex: 'companyName',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 100,
	},
	{
		title: '一级部门',
		dataIndex: 'department',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 100,
	},
	{
		title: '二级部门',
		dataIndex: 'twoDepartment',
		sorter: false,
		ellipsis: true,
		align: 'center',
		width: 100,
	},
	{
		title: '三级部门',
		dataIndex: 'threeDepartment',
		sorter: false,
		ellipsis: true,
		align: 'center',
		width: 100,
	},
	{
		title: '岗位',
		dataIndex: 'post',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 130,
	},
	{
		title: '员工种类',
		dataIndex: 'empKindTwo',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 130,
	},
	{
		title: '员工类型',
		dataIndex: 'empTypeName',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 100,
	},
];
//标准月薪    计薪时数 责任制
const columns2 = [
	{
		title: '标准月薪',
		dataIndex: 'monthlyPay',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 100,
	},
	{
		title: '工作日应出勤/时',
		dataIndex: 'stdLoborHours',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 120,
	},
	{
		title: '实际出勤/时',
		dataIndex: 'factLaborHours',
		sorter: true,
		align: 'center',
		width: 130,
	},
	{
		title: '工作日缺勤/时',
		dataIndex: 'absenceHours',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 130,
	},
	{
		title: '周末缺勤/时',
		sorter: true,
		dataIndex: 'weekAbsence',
		ellipsis: true,
		align: 'center',
		width: 110,
	},
	{
		title: '法定缺勤/时',
		sorter: true,
		dataIndex: 'holidayAbsence',
		ellipsis: true,
		align: 'center',
		width: 110,
	},
	{
		title: '有薪假/时',
		dataIndex: 'paidLeaveHours',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 100,
	},
	{
		title: '计薪时数',
		dataIndex: 'payHours',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 90,
	},
	{
		title: '底薪',
		dataIndex: 'basePay',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 90,
	},
];
//标准月薪    计薪时数 非责任制
const columns2_1 = [
	{
		title: '工作日应出勤/时',
		dataIndex: 'stdLoborHours',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 100,
	},
	{
		title: '实际出勤/时',
		dataIndex: 'factLaborHours',
		sorter: true,
		align: 'center',
		width: 130,
	},
	{
		title: '工作日缺勤/时',
		dataIndex: 'absenceHours',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 130,
	},
	{
		title: '周末缺勤/时',
		sorter: true,
		dataIndex: 'weekAbsence',
		ellipsis: true,
		align: 'center',
		width: 110,
	},
	{
		title: '法定缺勤/时',
		sorter: true,
		dataIndex: 'holidayAbsence',
		ellipsis: true,
		align: 'center',
		width: 110,
	},
	{
		title: '有薪假/时',
		dataIndex: 'paidLeaveHours',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 100,
	},
	{
		title: '计薪时数',
		dataIndex: 'payHours',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 90,
	},
	{
		title: '底薪',
		dataIndex: 'basePay',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 90,
	},
];
//各类津贴 基本工资（小计）...
const columns3 = [
	{
		title: '各类津贴',
		children: [
			{
				title: '主管加给',
				dataIndex: 'managerAdd',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '岗位津贴',
				dataIndex: 'postAllowance',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '技术津贴',
				dataIndex: 'skillAllowance',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '其他津贴',
				dataIndex: 'otherAllowance',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '津贴小计',
				dataIndex: 'allowanceSubtotal',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 100,
			},
		],
	},
	{
		title: '基本工资（小计）',
		dataIndex: 'basicWage',
		sorter: true,
		align: 'center',
		width: 160,
	},
	{
		title: '预付加班工资',
		dataIndex: 'prepaidOtPay',
		sorter: true,
		align: 'center',
		width: 130,
	},
	{
		title: '各类奖金',
		children: [
			{
				title: '绩效奖金',
				dataIndex: 'kpiPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '奖惩',
				dataIndex: 'award',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 60,
			},
			{
				title: 'EHS奖',
				dataIndex: 'ehsPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '安全奖',
				dataIndex: 'securityPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '内部稽核奖',
				dataIndex: 'auditPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 110,
			},
			{
				title: '技术改善奖',
				dataIndex: 'skillPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 110,
			},
			{
				title: '内荐奖',
				dataIndex: 'recommendPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '其他奖金',
				dataIndex: 'otherBonus',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '奖金小计',
				dataIndex: 'bonusSubtotal',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 100,
			},
		],
	},
];
//各类津贴 基本工资（小计）非责任制...
const columns3_1 = [
	{
		title: '各类津贴',
		children: [
			{
				title: '主管加给',
				dataIndex: 'managerAdd',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '岗位津贴',
				dataIndex: 'postAllowance',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '技术津贴',
				dataIndex: 'skillAllowance',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '其他津贴',
				dataIndex: 'otherAllowance',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '津贴小计',
				dataIndex: 'allowanceSubtotal',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 100,
			},
		],
	},
	{
		title: '基本工资（小计）',
		dataIndex: 'basicWage',
		sorter: true,
		align: 'center',
		width: 160,
	},
	{
		title: '各类奖金',
		children: [
			{
				title: '绩效奖金',
				dataIndex: 'kpiPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '奖惩',
				dataIndex: 'award',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 60,
			},
			{
				title: 'EHS奖',
				dataIndex: 'ehsPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '安全奖',
				dataIndex: 'securityPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '内部稽核奖',
				dataIndex: 'auditPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 110,
			},
			{
				title: '技术改善奖',
				dataIndex: 'skillPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 110,
			},
			{
				title: '超产奖',
				dataIndex: 'overProducePay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '内荐奖',
				dataIndex: 'recommendPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '其他奖金',
				dataIndex: 'otherBonus',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '奖金小计',
				dataIndex: 'bonusSubtotal',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 100,
			},
		],
	},
];
//责任制 各类补贴
const columns4_1 = [
	{
		title: '各类补贴',
		children: [
			{
				title: '夜班天数',
				dataIndex: 'nightShifDays',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '夜班补贴',
				dataIndex: 'nightShiftSubsidy',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '通讯补贴',
				dataIndex: 'communicationSubsidy',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '住宿/交通/餐补',
				dataIndex: 'stayTrafficMealPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 150,
			},
			{
				title: '其他补贴',
				dataIndex: 'otherSubsidy',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '补贴小计',
				dataIndex: 'subsidySubtotal',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 100,
			},
		],
	},
];
//非责任制 各类补贴
const columns4_2 = [
	{
		title: '各类补贴',
		children: [
			{
				title: '夜班天数',
				dataIndex: 'nightShifDays',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '夜班补贴',
				dataIndex: 'nightShiftSubsidy',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '工龄补贴',
				dataIndex: 'workAgeSubsidy',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '通讯补贴',
				dataIndex: 'communicationSubsidy',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '住宿/交通/餐补',
				dataIndex: 'stayTrafficMealPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 150,
			},
			{
				title: '接机补贴',
				dataIndex: 'airportPickUpPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '其他补贴',
				dataIndex: 'otherSubsidy',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '补贴小计',
				dataIndex: 'subsidySubtotal',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 100,
			},
		],
	},
];
//责任制 缺勤扣款
const columns5_1 = [
	{
		title: '缺勤扣款',
		children: [
			{
				title: '事假时数',
				dataIndex: 'sjHours',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			// {
			//     title: '周末事假时数',
			//     dataIndex: 'weekSjHours',
			//     sorter: true,
			//     ellipsis: true,
			//     align: 'center',
			//     width: 120,
			// },
			{
				title: '事假扣除',
				dataIndex: 'sjDeduction',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '病假时数',
				dataIndex: 'bjHours',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			// {
			//     title: '周末病假时数',
			//     dataIndex: 'weekBjHours',
			//     sorter: true,
			//     ellipsis: true,
			//     align: 'center',
			//     width: 120,
			// },
			{
				title: '病假扣除',
				dataIndex: 'bjDeduction',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '旷工时数',
				dataIndex: 'absentHours',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '旷工扣除',
				dataIndex: 'absentDeduction',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '迟到早退时数',
				dataIndex: 'lateOrEarlyHours',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 120,
			},
			{
				title: '迟到早退扣款',
				dataIndex: 'lateOrEarlyPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 120,
			},
			{
				title: '缺勤扣款小计',
				dataIndex: 'absenceDeduction',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 120,
			},
		],
	},
];
//加班费  缺勤扣款  加班费
const columns5 = [
	{
		title: '缺勤扣款',
		children: [
			{
				title: '事假时数',
				dataIndex: 'sjHours',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			// {
			//     title: '周末事假时数',
			//     dataIndex: 'weekSjHours',
			//     sorter: true,
			//     ellipsis: true,
			//     align: 'center',
			//     width: 120,
			// },
			{
				title: '事假扣除',
				dataIndex: 'sjDeduction',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '病假时数',
				dataIndex: 'bjHours',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			// {
			//     title: '周末病假时数',
			//     dataIndex: 'weekBjHours',
			//     sorter: true,
			//     ellipsis: true,
			//     align: 'center',
			//     width: 120,
			// },
			{
				title: '病假扣除',
				dataIndex: 'bjDeduction',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '旷工时数',
				dataIndex: 'absentHours',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '旷工扣除',
				dataIndex: 'absentDeduction',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 90,
			},
			{
				title: '迟到早退时数',
				dataIndex: 'lateOrEarlyHours',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 120,
			},
			{
				title: '迟到早退扣款',
				dataIndex: 'lateOrEarlyPay',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 120,
			},
			{
				title: '缺勤扣款小计',
				dataIndex: 'absenceDeduction',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 120,
			},
		],
	},
	{
		title: '加班费',
		children: [
			{
				title: '工作日加班时数',
				dataIndex: 'oT1',
				sorter: true,
				align: 'center',
				width: 140,
			},
			{
				title: '工作日加班费',
				dataIndex: 'oT1Pay',
				sorter: true,
				align: 'center',
				width: 130,
			},
			{
				title: '周末加班时数',
				dataIndex: 'oT2',
				sorter: true,
				align: 'center',
				width: 130,
			},
			{
				title: '周末加班费',
				dataIndex: 'oT2Pay',
				sorter: true,
				align: 'center',
				width: 110,
			},
			{
				title: '法定加班时数',
				dataIndex: 'oT3',
				sorter: true,
				align: 'center',
				width: 130,
			},
			{
				title: '法定加班费',
				dataIndex: 'oT3Pay',
				sorter: true,
				align: 'center',
				width: 110,
			},
			{
				title: '加班费小计',
				dataIndex: 'otCostSubtotal',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 120,
			},
		],
	},
];
//责任制 缺勤工资...
const columns6_1 = [
	{
		title: '新进/离职缺勤扣款',
		dataIndex: 'absenceWage',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 150,
	},
	{
		title: '应发工资',
		dataIndex: 'shouldWage',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 90,
	},
];
//非责任制  差额补贴 缺勤工资...
const columns6_2 = [
	{
		title: '保底差额补贴',
		dataIndex: 'differenceSubsidy',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 120,
	},
	{
		title: '新进/离职缺勤扣款',
		dataIndex: 'absenceWage',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 150,
	},
	{
		title: '应发工资',
		dataIndex: 'shouldWage',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 90,
	},
];
//代扣代缴 现金支付...
const columns7 = [
	{
		title: '代扣代缴',
		children: [
			{
				title: '社保基数',
				dataIndex: 'ssPaymentBase',
				sorter: true,
				align: 'center',
				width: 130,
			},
			{
				title: '社保代扣',
				dataIndex: 'ssWithholding',
				sorter: true,
				align: 'center',
				width: 100,
			},
			{
				title: '公积金基数',
				dataIndex: 'pfPaymentBase',
				sorter: true,
				align: 'center',
				width: 120,
			},
			{
				title: '公积金代扣',
				dataIndex: 'pfWithholding',
				sorter: true,
				align: 'center',
				width: 120,
			},
			{
				title: '个人所得税',
				dataIndex: 'personTax',
				sorter: true,
				align: 'center',
				width: 110,
			},
			{
				title: '代扣代缴小计',
				dataIndex: 'withholdingSubtotal',
				sorter: true,
				ellipsis: true,
				align: 'center',
				width: 120,
			},
		],
	},
	{
		title: '公司成本',
		children: [
			{
				title: '社保成本',
				dataIndex: 'companySsPayment',
				sorter: true,
				align: 'center',
				width: 120,
			},
			{
				title: '公积金成本',
				dataIndex: 'companyPfPayment',
				sorter: true,
				align: 'center',
				width: 120,
			},
		],
	},
	{
		title: '现金支付',
		dataIndex: 'cashPayment',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 90,
	},
	{
		title: '实发工资',
		dataIndex: 'realWages',
		sorter: true,
		ellipsis: true,
		align: 'center',
		width: 90,
	},
	{
		title: '其它个人借记',
		dataIndex: 'otherPersonalLoan',
		sorter: true,
		align: 'center',
		width: 140,
	},
	{
		title: '银行转账工资',
		dataIndex: 'bankTransfer',
		sorter: true,
		align: 'center',
		width: 140,
	},
	{
		title: '状态',
		dataIndex: 'stateName',
		sorter: true,
		align: 'center',
		width: 100,
	},
	{
		title: '审核人',
		dataIndex: 'auditUserName',
		sorter: true,
		align: 'center',
		width: 100,
	},
	{
		title: '审核时间',
		dataIndex: 'auditTime',
		sorter: true,
		align: 'center',
		width: 150,
	},
	{
		title: '员工确认时间',
		dataIndex: 'selfCheckTime',
		sorter: true,
		align: 'center',
		width: 150,
	},
	{
		title: '员工查看时间',
		dataIndex: 'watchTime',
		sorter: true,
		align: 'center',
		width: 150,
	},
	{
		title: '员工查看状态',
		dataIndex: 'isWatch',
		sorter: true,
		align: 'center',
		width: 150,
	},
	{
		title: '工资生成人',
		dataIndex: 'passUserName',
		sorter: true,
		align: 'center',
		width: 120,
	},
	{
		title: '工资生成时间',
		dataIndex: 'passTime',
		sorter: true,
		align: 'center',
		width: 150,
	},
	{
		title: '是否发薪',
		dataIndex: 'isPay',
		sorter: true,
		align: 'center',
		width: 100,
	},
];

// 薪资明细页面
export default {
	name: 'salary-detail',
	mixins: [AppComponentBase],
	components: {
		ImportExcelModel,
		ImportPersonTaxMode,
		SalarySetting,
		PushToEmpModel,
		CreateOrEditSalaryItem,
		ImportPFWithholdingModel,
		BatchUpdateIsPay,
	},
	data() {
		return {
			companyTreeList: [],
			comPanyId: undefined,
			departmentId: undefined,
			//部门树
			DepTreeData: [],
			DepTree: [],
			filterText: '',
			columns: [],
			tableData: [],
			//发薪条件
			payConditionsCode: undefined,
			PayConditions: [],
			salaryDetailState: undefined,
			SalaryDetailStates: [],
			//考勤周期
			attCycle: moment(),
			attCycleStartTime: '',
			attCycleEndTime: '',
			isTwoDepGroupExport: false,
			isThreeDepGroupExport: false,
			//表格是否多选
			isMultiple: true,
			ispayList:[
				{code:0,name:"否"},
				{code:1,name:"是"},
			],
			ispay:undefined
		};
	},
	created() {
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._enterpriseAttendCycleServiceProxy = new EnterpriseAttendCycleServiceProxy(this.$apiUrl, this.$api);
		this._empSalaryDetailServiceProxy = new EmpSalaryDetailServiceProxy(this.$apiUrl, this.$api);
		this._fileDownloadService = fileDownloadService;
		this._empSalaryItemsServiceProxy = new EmpSalaryItemsServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		try {
			this.loading = true;
			//发薪条件
			this.PayConditions = await Dic.getInstance().getDicAsync('EmpTypeKind1');
			//默认第一条
			try {
				this.payConditionsCode = this.PayConditions[0].itemDetailCode;
				//责任制人员
				if (this.payConditionsCode === '1') {
					this.columns = [
						...columns1,
						...columns2,
						...columns3,
						...columns4_1,
						...columns5_1,
						...columns6_1,
						...columns7,
					];
				} //非责任制人员
				else {
					this.columns = [
						...columns1,
						...columns2_1,
						...columns3_1,
						...columns4_2,
						...columns5,
						...columns6_2,
						...columns7,
					];
				}
			} catch (e) {
				this.columns = [
					...columns1,
					...columns2,
					...columns3,
					...columns4_2,
					...columns5,
					...columns6_2,
					...columns7,
				];
				this.payConditionsCode = undefined;
			}

			this.SalaryDetailStates = await Dic.getInstance().getDicAsync('QYB_SL_State');
			//去除已结账
			this.SalaryDetailStates=[...this.SalaryDetailStates.filter(item=>item.itemDetailCode!="4")];
			await this.companyTreeInit();
			//获取考勤周期
			await this.getCycle('init');
			this.coumnsInit();
		} catch (e) {
		} finally {
			this.loading = false;
			this.getData();
		}
	},
	methods: {
		coumnsInit() {
			//责任制人员
			if (this.payConditionsCode === '1') {
				this.columns = [
					...columns1,
					...columns2,
					...columns3,
					...columns4_1,
					...columns5_1,
					...columns6_1,
					...columns7,
				];
			} //非责任制人员
			else {
				this.columns = [
					...columns1,
					...columns2_1,
					...columns3_1,
					...columns4_2,
					...columns5,
					...columns6_2,
					...columns7,
				];
			}
			this.columns = this.getColSpanColumn('薪资明细', this.columns);
		},
		refresh() {
			this.coumnsInit();
			this.search();
		},
		getData() {
			this.loading = true;
			this._empSalaryDetailServiceProxy
				.getPaged(
					this.attCycle.year(),
					this.attCycle.month() + 1,
					this.comPanyId,
					this.departmentId,
					this.payConditionsCode,
					this.salaryDetailState,
					false,
					false,
					this.ispay,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.termDate = item.termDate ? moment(item.termDate).format('YYYY-MM-DD') : '';
						item.hireDate = item.hireDate ? moment(item.hireDate).format('YYYY-MM-DD') : '';

						item.auditTime = item.auditTime ? moment(item.auditTime).format('YYYY-MM-DD hh:mm') : '';
						item.selfCheckTime = item.selfCheckTime
							? moment(item.selfCheckTime).format('YYYY-MM-DD hh:mm')
							: '';
						item.passTime = item.passTime ? moment(item.passTime).format('YYYY-MM-DD hh:mm') : '';
						item.watchTime = item.watchTime ? moment(item.watchTime).format('YYYY-MM-DD hh:mm') : '';
						item.isWatch = item.isWatch ? '已查看' : '未查看';
					});
					this.totalItems = res.totalCount;
				})
				.finally((res) => {
					this.loading = false;
				});
		},
		createSalary() {
			modalHelper
				.create(
					CreateSalaryDetailEmpModel,
					{
						_selectedRows: [],
						_selectedRowKeys: [],
						isMultiple: true,
						year: this.attCycle.year(),
						month: this.attCycle.month() + 1,
						startTime: this.attCycleStartTime,
						endTime: this.attCycleEndTime,
					},
					{
						width: '900px',
						closable: false,
					}
				)
				.subscribe((res) => {
					if (res) {
						this.search();
					}
				});
		},
		//计算
		calc() {
			let empIds = _.map(this.selectedRows, 'empId');
			let str = '您确认要进行薪资计算操作吗？';
			if (empIds.length > 0) {
				str = '当前选中' + empIds.length + '个人员,您确认要进行薪资计算操作吗？';
			}
			this.$confirm({
				title: '操作确认',
				content: str,
				cancelText: '取消',
				okText: '确认',
				onOk: () => {
					this.loading = true;

					let input = new CalcOrCheckOutInput();
					input.year = this.attCycle.year();
					input.month = this.attCycle.month() + 1;
					input.companyId = _.join(this.comPanyId,",");
					input.departmentId = _.join(this.departmentId,",");
					input.salaryPay = this.payConditionsCode;
					input.startTime = moment(this.attCycleStartTime);
					input.endTime = moment(this.attCycleEndTime);
					input.empIds = empIds;
					this._empSalaryDetailServiceProxy
						.salaryCalc(input)
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.$notification['success']({
								message: '计算成功',
							});
							this.refresh();
						});
				},
			});
		},
		//批量审核
		batchAudit() {
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn('至少选择一项进行操作');
			}
			this.$confirm({
				title: '审核确认',
				content: '确认要执行此操作吗？',
				cancelText: '取消',
				okText: '确认',
				onOk: () => {
					this.loading = true;
					this._empSalaryDetailServiceProxy.batchAudit(this.selectedRowKeys).then((res) => {
						this.$notification['success']({
							message: '审核成功',
						});
						this.search();
					});
				},
			});
		},
		//批量修改状态
		batchEditState() {
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn('至少选择一项进行操作');
			}
			modalHelper
				.create(
					BatchUpdateState,
					{
						ids: this.selectedRowKeys,
					},
					{
						width: '300px',
					}
				)
				.subscribe((res) => {
					if (res) {
						this.search();
					}
				});
		},
		/**
		 * 批量修改是否发薪
		 */
		batchEditIsPay() {
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn('至少选择一项进行操作');
			}
			modalHelper
				.create(
					BatchUpdateIsPay,
					{
						ids: this.selectedRowKeys,
					},
					{
						width: '400px',
					}
				)
				.subscribe((res) => {
					if (res) {
						this.selectedRowKeys = [];
						this.selectRows = [];
						this.search();
					}
				});
		},
		//生成工资表
		pay() {
			let empIds = _.map(this.selectedRows, 'empId');
			let str = '您确认要进行生成工资表操作吗？';
			if (empIds.length > 0) {
				str = '当前选中' + empIds.length + '个人员,您确认要进行生成工资表操作吗？';
			}
			this.$confirm({
				title: '操作确认',
				content: str,
				cancelText: '取消',
				okText: '确认',
				onOk: () => {
					this.loading = true;

					let input = new CalcOrCheckOutInput();
					input.year = this.attCycle.year();
					input.month = this.attCycle.month() + 1;
					input.companyId = _.join(this.comPanyId,",");
					input.departmentId = _.join(this.departmentId,",");
					input.salaryPay = this.payConditionsCode;
					input.startTime = moment(this.attCycleStartTime);
					input.endTime = moment(this.attCycleEndTime);
					input.empIds = empIds;
					this._empSalaryDetailServiceProxy
							.checkOut(input)
							.finally(() => {
								this.loading = false;
							})
							.then((res) => {
								this.$notification['success']({
									message: '生成工资表成功',
								});
								this.refresh();
							});
				},
			});
		},
		createOrEidtSalary(record) {
			modalHelper
				.create(
					CreateOrEditSalaryItem,
					{
						empId: record.empId,
						year: record.slYear,
						month: record.slMonth,
						startTime: this.attCycleStartTime,
						endTime: this.attCycleEndTime,
						realName: record.realName,
					},
					{
						width: '75%',
					}
				)
				.subscribe((res) => {
					if (res) {
						this.search();
					}
				});
		},
		/**
		 *导入计算项
		 */
		importData() {
			modalHelper
				.create(
					ImportExcelModel,
					{
						year: this.attCycle.year(),
						month: this.attCycle.month() + 1,
						attCycleStartTime: this.attCycleStartTime,
						attCycleEndTime: this.attCycleEndTime,
					},
					{ width: '800px' }
				)
				.subscribe((res) => {
					if (res) {
						this.search();
					}
				});
		},
		/**
		 * 个税导入
		 */
		importPersonTax() {
			modalHelper
				.create(
					ImportPersonTaxMode,
					{
						year: this.attCycle.year(),
						month: this.attCycle.month() + 1,
						attCycleStartTime: this.attCycleStartTime,
						attCycleEndTime: this.attCycleEndTime,
					},
					{
						width: '800px',
					}
				)
				.subscribe((res) => {
					if (res) {
						this.search();
					}
				});
		},
		/**
		 * 薪资明细导出
		 */
		exporter() {
			if (this.tableData.length <= 0) {
				return abp.message.warn(this.l('NoData'));
			}
			this.loading = true;
			this._empSalaryDetailServiceProxy
				.getToExcelFile(
					this.attCycle.year(),
					this.attCycle.month() + 1,
					this.comPanyId,
					this.departmentId,
					this.payConditionsCode,
					this.salaryDetailState,
					this.isTwoDepGroupExport,
					this.isThreeDepGroupExport,
						this.ispay,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this._fileDownloadService.downloadTempFile(res);
				});
		},
		/**
		 * 计算项导出
		 */
		itemexporter() {
			if (this.tableData.length <= 0) {
				return abp.message.warn(this.l('NoData'));
			}
			this.loading = true;
			this._empSalaryItemsServiceProxy
				.getToExcelFile(
					this.attCycle.year(),
					this.attCycle.month() + 1,
					this.comPanyId,
					this.departmentId,
					this.payConditionsCode,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this._fileDownloadService.downloadTempFile(res);
				});
		},
		async getCycle(type) {
			if (type === 'init') {
				const { startTime, endTime, yearOrMonth } = await this.initCycle();
				this.attCycleStartTime = startTime;
				this.attCycleEndTime = endTime;
				this.attCycle = moment(yearOrMonth);
			} else {
				const { startTime, endTime } = await this.getCycleByDate();
				this.attCycleStartTime = startTime;
				this.attCycleEndTime = endTime;
			}
		},
		/**
		 * 全员个税导出
		 */
		personTaxAll() {
			if (this.tableData.length <= 0) {
				return abp.message.warn(this.l('NoData'));
			}
			this.loading = true;
			this._empSalaryDetailServiceProxy
				.exportPersonTaxAll(
					this.attCycle.year(),
					this.attCycle.month() + 1,
					this.comPanyId,
					this.departmentId,
					this.payConditionsCode,
					this.salaryDetailState,
					false,
					false,
						undefined,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this._fileDownloadService.downloadTempFile(res);
				});
		},
		/**
		 * 新进人员个税导出
		 */
		personTaxByNewEmp() {
			if (this.tableData.length <= 0) {
				return abp.message.warn(this.l('NoData'));
			}
			this.loading = true;
			this._empSalaryDetailServiceProxy
				.exportPersonTaxByNewEmp(
					this.attCycle.year(),
					this.attCycle.month() + 1,
					this.comPanyId,
					this.departmentId,
					this.salaryDetailState,
					moment(this.attCycleStartTime),
					moment(this.attCycleEndTime)
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this._fileDownloadService.downloadTempFile(res);
				});
		},
		/**
		 * 社保补缴导入
		 */
		importPfWithholding() {
			modalHelper
				.create(
					ImportPFWithholdingModel,
					{
						year: this.attCycle.year(),
						month: this.attCycle.month() + 1,
						attCycleStartTime: this.attCycleStartTime,
						attCycleEndTime: this.attCycleEndTime,
					},
					{
						width: '800px',
					}
				)
				.subscribe((res) => {
					if (res) {
						this.search();
					}
				});
		},
		/**
		 * 薪资设置
		 */
		salarySetting() {
			modalHelper
				.create(
					SalarySetting,
					{},
					{
						width: '600px',
					}
				)
				.subscribe((res) => {});
		},
		/**
		 * 删除
		 */
		deletes() {
			if (this.selectedRows.length <= 0) {
				return abp.message.warn('请至少选择一项进行操作');
			}
			let empIds = _.map(this.selectedRows, 'empId');
			this.$confirm({
				title: '确认操作',
				content: '你确认要删除这' + this.selectedRows.length + '项数据吗',
				okText: '确认',
				cancelText: '取消',
				onOk: () => {
					this.loading = true;
					this._empSalaryDetailServiceProxy
						.batchDelete(this.attCycle.year(), this.attCycle.month() + 1, empIds)
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.search();
						});
				},
			});
		},
		/**
		 * 初始化考勤周期
		 */
		async initCycle() {
			return new Promise((resolve, reject) => {
				this.loading = true;
				this._enterpriseAttendCycleServiceProxy.getSalaryCycle().then((res) => {
					resolve(res);
				});
			});
		},
		/**
		 * 获取指定年月的周期
		 */
		async getCycleByDate() {
			return new Promise((resolve, reject) => {
				this.loading = true;
				this._enterpriseAttendCycleServiceProxy
					.getByTenant(this.attCycle.year(), this.attCycle.month() + 1)
					.then((res) => {
						resolve(res);
					});
			});
		},
		//考勤周期选择
		onAttCycleChange(e) {
			this.attCycle = e;
			this.getCycle();
			this.search();
		},
		//发薪条件选择
		payConditionsChange() {
			//责任制人员
			if (this.payConditionsCode === '1') {
				this.columns = [
					...columns1,
					...columns2,
					...columns3,
					...columns4_1,
					...columns5,
					...columns6_1,
					...columns7,
				];
			} //非责任制人员
			else {
				this.columns = [
					...columns1,
					...columns2,
					...columns3,
					...columns4_2,
					...columns5,
					...columns6_2,
					...columns7,
				];
			}
			this.refresh();
		},

		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		companyTreeInit() {
			return new Promise((resolve) => {
				this._CompanyConfigServiceProxy
					.getPaged(undefined, '', 100, 0)
					.finally(() => {
						this.loading = false;
					})
					.then((res) => {
						this.companyList = res.items;
						this.companyList.forEach((item) => {
							if (item.parentId == 0) {
								var obj = {
									key: item.id,
									value: item.id,
									title: item.fullName,
								};
								this.companyTreeList.push(obj);
							}
						});
						this.initCompanyTree(this.companyTreeList);
						resolve();
					});
			});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		handleChange(value) {
			this.comPanyId = value;
			this.departmentId = undefined;
			this.refresh();
			this.getDepTreeData();
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.comPanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.departmentId = e;
		},
	},
};
</script>

<style scoped>
.margin-left {
	margin-left: -200px;
}
</style>
