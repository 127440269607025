<template>
    <a-spin :spinning="loading">
        <div class="modal-header">
            <div class="modal-title">
                <div>发送工资条</div>
            </div>
        </div>
        <div class="my-md">
            <a-row class="text">
                1.当前月度：<b>{{year}}</b>-<b>{{month}}</b>
            </a-row>
            <a-row class="text">
                2.发送规则：默认当前薪资周期，状态是审核完成的数据，且没有发送过薪资确认消息的所有员工
            </a-row>
        </div>
        <!-- 按钮 -->
        <div class="modal-footer">

            <a-button @click="close">
                <a-icon type="close-circle"/>
                {{ l('Cancel') }}
            </a-button>
            <a-button :type="'primary'" @click="save">
                <a-icon type="save"/>
                确认发送
            </a-button>
        </div>
    </a-spin>
</template>

<script>

    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import {EmpSalaryDetailServiceProxy, EnterpriseAttendCycleServiceProxy} from "../../../../shared/service-proxies";
    import moment from "moment";

    export default {
        name: "push-to-emp-model",
        mixins: [ModalComponentBase],
        data() {
            return {
                year: undefined,
                month: undefined,
                attCycle: undefined
            }
        },
        created() {
            this.fullData();
            this._enterpriseAttendCycleServiceProxy = new EnterpriseAttendCycleServiceProxy(this.$apiUrl, this.$api);
            this._empSalaryDetailServiceProxy = new EmpSalaryDetailServiceProxy(this.$apiUrl, this.$api)
        },
        async mounted() {
            try {
                this.loading = true;
                const {startTime, endTime, yearOrMonth} = await this.initCycle();
                this.attCycle = moment(yearOrMonth);
                this.year = this.attCycle.year();
                this.month = this.attCycle.month() + 1;
            }
            finally {
                this.loading = false;
            }

        },
        methods: {
            /**
             * 初始化考勤周期
             */
            async initCycle() {
                return new Promise((resolve, reject) => {
                    this._enterpriseAttendCycleServiceProxy.getSalaryCycle().then((res) => {
                        resolve(res);
                    });
                });
            },
            save() {
                this.loading = true;
                this._empSalaryDetailServiceProxy.appPushToEmp(this.year, this.month)
                    .finally(() => {
                        this.loading = false;
                    })
                    .then(res => {
                        this.$notification["success"]({
                            message: this.l("发送成功"),
                        });
                        this.success(true);
                    })
            }
        }
    }
</script>

<style scoped>
    .text {
        padding: 5px 0px;
        font-size: 15px;
    }
</style>
