<template>
    <a-spin :spinning="loading">
        <div class="modal-header">
            <div class="modal-title">
                <div>批量修改是否发薪</div>
            </div>
        </div>
        <a-row class="d-flex align-items-center">
            <a-col>
                是否发薪：
            </a-col>
            <a-col>
                <a-select
                        placeholder="状态"
                        style="width:200px"
                        v-model="ispay">
                    <a-select-option v-for="item in ispayList"
                                     :key="item.code">
                        {{item.name}}
                    </a-select-option>
                </a-select>
            </a-col>
        </a-row>
        <!-- 按钮 -->
        <div class="modal-footer">

            <a-button @click="close">
                <a-icon type="close-circle"/>
                {{ l('Cancel') }}
            </a-button>
            <a-button :type="'primary'" @click="save">
                <a-icon type="save"/>
                保存
            </a-button>
        </div>
    </a-spin>
</template>

<script>
    import {EmpSalaryDetailServiceProxy, UpdateSalaryDetailIsPayInput} from "../../../../shared/service-proxies";
    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";

    export default {
        name: "batch-update-ispay",
        mixins:[ModalComponentBase],
        data(){
            return{
                ispayList:[
                    {
                        name:"是",
                        code:1,
                    },
                    {
                        name:"否",
                        code:0,
                    }
                ],
                ispay:1,
                ids: [],
            }
        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            this._empSalaryDetailServiceProxy = new EmpSalaryDetailServiceProxy(this.$apiUrl, this.$api)
        },
        mounted() {

        },
        methods:{
            save(){
                let input=new UpdateSalaryDetailIsPayInput();
                input.ids=this.ids;
                input.isPay=this.ispay;
                this.locale=true;
                this._empSalaryDetailServiceProxy.updateSalaryDetailIsPay(input)
                .finally(()=>{this.loading=false;})
                .then(res=>{
                    this.$notification["success"]({
                        message: '保存成功',
                    });
                    this.success(true);
                })
            }
        }
    }
</script>

<style scoped>

</style>
