<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<a-icon type="calculator" />
					<span>{{ type === 'calc' ? '计算' : '结账' }}</span>
				</div>
			</div>
			<a-row>
				<a-col :span="4">
					<q-label required :label="l('薪资月份')"> </q-label>
				</a-col>
				<a-col :span="9">
					<a-month-picker
						:allowClear="false"
						v-model="attCycle"
						@change="attCycleChang"
						style="width: 200px"
					/>
				</a-col>
				<a-col :span="6">
					<p style="font-size: 10px;color:#BEBEBE ">{{ startTime }}~{{ endTime }}</p>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label :label="l('发薪条件')"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-select
						style="width: 200px"
						show-search
						allowClear
						placeholder="请选择发薪条件"
						@change="(e) => (input.salaryPay = e)"
						v-model="payConditionsCode"
					>
						<a-select-option v-for="item in PayConditions" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label :label="l('人员')"> </q-label>
				</a-col>
				<a-col :span="20">
					<div class="CheckInUser" @click="selectUser">
						<a-tag color="#2db7f5" v-for="item in userList" :key="item.id" style="cursor: pointer;">
							{{ item.realName }}
						</a-tag>
					</div>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label :label="l('公司')"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-tree-select
						class="left"
						allowClear
						@change="handleCompanyChange"
						:placeholder="l('Company')"
						style="width:200px;"
						tree-default-expand-all
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						v-model="input.companyId"
					/>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label :label="l('部门')"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-tree-select
						v-model="input.departmentId"
						allowClear
						style="width:200px;"
						show-search
						:filterTreeNode="searchTree"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="DepTreeData"
						:placeholder="l('Department')"
						tree-default-expand-all
						@select="SelectDepartmentTree"
					>
					</a-tree-select>
				</a-col>
			</a-row>

			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save">
					{{ type === 'calc' ? '计算' : '结账' }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import { ModalComponentBase } from '../../../../shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import ModalHelper from '../../../../shared/helpers/modal/modal-helper';
import SelectUserTag from '@/components/select-user-tag/select-user-tag.vue';
import {
	CompanyServiceProxy,
	DepartmentServiceProxy,
	EmpSalaryDetailServiceProxy,
	EnterpriseAttendCycleServiceProxy,
	CalcOrCheckOutInput,
} from '../../../../shared/service-proxies';
import moment from 'moment';
import { abpService } from '../../../../shared/abp';
import Dic from '../../../../shared/utils/dic';
import ObjectUtils from '../../../../shared/utils/ObjectUtils';
import DepTreeUntils from '../../../organization/department/dep-tree-untils/dep-tree-untils';

//薪资计算  薪资结账
export default {
	name: 'salary-calc-and-pay',
	mixins: [ModalComponentBase],
	data() {
		return {
			zh_CN,
			//上一个页面的数据
			//发薪月
			attCycle: undefined,
			//页面类型
			type: '',

			//发薪条件
			PayConditions: [],
			payConditionsCode: undefined,

			userList: [],
			companyTreeList: [], //公司
			comPanyId: undefined, //公司id
			departmentId: undefined,
			//部门树
			DepTreeData: [],
			DepTree: [],
			input: new CalcOrCheckOutInput(),

			startTime: '',
			endTime: '',
		};
	},
	async mounted() {
		this.fullData();
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._empSalaryDetailServiceProxy = new EmpSalaryDetailServiceProxy(this.$apiUrl, this.$api);
		this.attenanceDateApi = new EnterpriseAttendCycleServiceProxy(this.$apiUrl, this.$api);

		this.PayConditions = await Dic.getInstance().getDicAsync('EmpTypeKind1');

		if (this.attCycle) {
			this.attCycleChang(this.attCycle);
		}
		this.input = ObjectUtils.initDto(this.input);

		this.initCompany();
		if (abpService.abp.userInfo.companyId) {
			this.input.companyId = abpService.abp.userInfo.companyId;
			this.handleCompanyChange(this.input.companyId);
		}
	},
	methods: {
		//发薪条件选择
		payConditionsChange(e) {
			this.input.salaryPay = e;
		},
		//薪资月份选择
		attCycleChang() {
			this.loading = true;
			this.attenanceDateApi
				.getByTenant(this.attCycle.year(), this.attCycle.month() + 1)
				.finally(() => {
					this.loading = false;
				})
				.then(({ startTime, endTime }) => {
					this.startTime = startTime;
					this.endTime = endTime;
					this.$forceUpdate();
				});
		},
		selectUser() {
			let ids = _.map(this.userList, 'id');
			ModalHelper.create(
				SelectUserTag,
				{
					_selectedRowKeys: ids,
					_selectedRows: this.userList,
					isMultiple: true,
				},
				{
					width: '900px',
					closable: false,
				}
			).subscribe((res) => {
				if (res) {
					this.userList = [];
					res.forEach((item, index) => {
						this.userList = [...this.userList, item];
					});
				}
			});
		},
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		// 公司选择
		handleCompanyChange(value) {
			this.comPanyId = value;
			// this.getData();
			if (!value) {
				this.departmentId = undefined;
			}
			this.getDepTreeData();
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.comPanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					// this.loading = false;
				});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.departmentId = e;
		},
		//初始化公司
		initCompany() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					// this.spinning = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					// if (this.companyTreeList.length > 0) {
					//     this.handleCompanyChange(this.companyTreeList[0].key);
					// }
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		save() {
			this.input.empIds = _.map(this.userList, 'id');
			this.input.companyId = this.comPanyId;
			this.input.departmentId = this.departmentId;
			this.input.startTime = moment(this.startTime);
			this.input.endTime = moment(this.endTime);
			this.input.year = this.attCycle.year();
			this.input.month = this.attCycle.month() + 1;

			this.loading = true;
			if (this.type === 'calc') {
				this._empSalaryDetailServiceProxy
					.salaryCalc(this.input)
					.finally(() => {
						this.loading = false;
					})
					.then((res) => {
						this.$notification['success']({
							message: '计算成功',
						});
						this.success(true);
					});
			} else {
				this._empSalaryDetailServiceProxy
					.checkOut(this.input)
					.finally(() => {
						this.loading = false;
					})
					.then((res) => {
						this.$notification['success']({
							message: '结账成功',
						});
						this.success(true);
					});
			}
		},
	},
};
</script>

<style scoped>
.CheckInUser {
	border: 1px solid #d9d9d9;
	min-height: 35px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	padding-left: 5px;
	line-height: 35px;
	overflow-x: auto;
	cursor: pointer;
}
</style>
