<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<div class="modal-header">
				<div class="modal-title">
					<div>编辑薪资变化项({{ realName }})</div>
				</div>
			</div>
			<div>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('绩效奖金')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input placeholder="请输入绩效奖金" v-model="entity.kpiPay" />
					</a-col>
					<a-col :span="4">
						<q-label :label="l('EHS奖')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input placeholder="请输入EHS奖" v-model="entity.ehsPay" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('安全奖')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input placeholder="请输入安全奖" v-model="entity.securityPay" />
					</a-col>
					<a-col :span="4">
						<q-label :label="l('内部稽核奖')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input placeholder="请输入内部稽核奖" v-model="entity.auditPay" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('技术改善奖')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input placeholder="请输入技术改善奖" v-model="entity.skillPay" />
					</a-col>
					<a-col :span="4">
						<q-label :label="l('超产奖')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input placeholder="请输入超产奖" v-model="entity.overProducePay" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('内荐奖')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input placeholder="请输入内荐奖" v-model="entity.recommendPay" />
					</a-col>
					<a-col :span="4">
						<q-label :label="l('奖惩')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input placeholder="请输入奖惩" v-model="entity.award" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('其它奖金')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input placeholder="请输入其它奖金" v-model="entity.otherBonus" />
					</a-col>
					<a-col :span="4">
						<q-label :label="l('住宿/交通/餐补或油补')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input placeholder="请输入住宿/交通/餐补或油补" v-model="entity.stayTrafficMealPay" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('接机补贴')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input placeholder="请输入接机补贴" v-model="entity.airportPickUpPay" />
					</a-col>
					<a-col :span="4">
						<q-label :label="l('其它补贴')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input placeholder="请输入其它补贴" v-model="entity.otherSubsidy" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('现金支付')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input placeholder="请输入现金支付" v-model="entity.cashPayment" />
					</a-col>
					<a-col :span="4">
						<q-label :label="l('其它个人借支')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input placeholder="请输入其它个人借支" v-model="entity.otherPersonalLoan" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('个税')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input placeholder="请输入个税" v-model="entity.personTax" />
					</a-col>
					<a-col :span="4">
						<q-label :label="l('社保补缴')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input placeholder="请输入社保补缴" v-model="entity.ssWithholdingPay" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('公积金补缴')"> </q-label>
					</a-col>
					<a-col :span="8">
						<a-input placeholder="请输入公积金补缴" v-model="entity.pfWithholdingPay" />
					</a-col>
<!--					<a-col :span="4">-->
<!--						<q-label :label="l('公司社保成本')"> </q-label>-->
<!--					</a-col>-->
<!--					<a-col :span="8">-->
<!--						<a-input placeholder="请输入公司社保成本" v-model="entity.companySsPayment" />-->
<!--					</a-col>-->
				</a-row>
<!--				<a-row>-->
<!--					<a-col :span="4">-->
<!--						<q-label :label="l('公司公积金成本')"> </q-label>-->
<!--					</a-col>-->
<!--					<a-col :span="8">-->
<!--						<a-input placeholder="请输入公司公积金成本" v-model="entity.companyPfPayment" />-->
<!--					</a-col>-->
<!--				</a-row>-->
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save">
					<a-icon type="save" />
					保存
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import {
	CreateOrUpdateEmpSalaryItemsInput,
	EmpSalaryItemsEditDto,
	EmpSalaryItemsServiceProxy,
} from '../../../../shared/service-proxies';
import moment from 'moment';

export default {
	name: 'create-or-edit-salary-item',
	mixins: [ModalComponentBase],
	data() {
		return {
			zh_CN,
			empId: undefined,
			year: undefined,
			month: undefined,
			startTime: undefined,
			endTime: undefined,
			entity: new EmpSalaryItemsEditDto(),
			realName: undefined,
		};
	},
	created() {
		this.fullData();
		this._empSalaryItemsServiceProxy = new EmpSalaryItemsServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.loading = true;
			this._empSalaryItemsServiceProxy
				.getForEdit(this.empId, this.year, this.month)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.entity = res;
				});
		},
		save() {
			let input = new CreateOrUpdateEmpSalaryItemsInput();
			input.empSalaryItems = this.entity;
			input.startTime = moment(this.startTime);
			input.endTime = moment(this.endTime);
			this.loading = true;
			this._empSalaryItemsServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped></style>
