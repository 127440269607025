<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<span>计算设置</span>
				</div>
			</div>
			<div>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('夜班津贴')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-input-number v-model="entity.subsidy" :min="0" style="width: 200px;" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('保底工资')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-input-number v-model="entity.salary" :min="0" style="width: 200px;" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('薪资周期')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-month-picker v-model="salaryCycle" :allowClear="false" style="width: 200px;" />
					</a-col>
				</a-row>
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { SalarySettingEditDto, TenantSettingsServiceProxy } from '../../../shared/service-proxies';
import ModalComponentBase from '../../../shared/component-base/modal-component-base';
import moment from 'moment';

export default {
	name: 'salary-setting',
	mixins: [ModalComponentBase],
	data() {
		return {
			zh_CN,
			entity: new SalarySettingEditDto(),
			salaryCycle: undefined,
		};
	},
	created() {
		this._tenantSettingsServiceProxy = new TenantSettingsServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.loading = true;
			this._tenantSettingsServiceProxy
				.getSalarySettings()
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.entity = res;
					if (this.entity.year && this.entity.month) {
						this.salaryCycle = moment(this.entity.year + '-' + this.entity.month);
					} else {
						this.salaryCycle = undefined;
					}
				});
		},
		save() {
			if (this.salaryCycle) {
				this.entity.year = moment(this.salaryCycle).year();
				this.entity.month = moment(this.salaryCycle).month() + 1;
			}
			this.loading = true;
			this._tenantSettingsServiceProxy
				.updateSalarySetting(this.entity)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped>
.ant-col {
	line-height: 20px;
}
</style>
